@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Lato-Bold";
  src:
    local("Lato"),
    url(./assets/fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Light";
  src:
    local("Lato"),
    url(./assets/fonts/Lato-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src:
    local("Lato"),
    url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

* {
  box-sizing: border-box !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-container {
  padding: 0 !important;
  // background-color: transparent !important;
  overflow: visible !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

select,
select:active,
select:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#menu,
#responsive-menu {
  z-index: 999 !important;
}

.custom-shadow {
  box-shadow: 1px 1px 6px 0px #bbb9b9 !important;
}

.fk-input:focus {
  border-color: gray !important;
}

ngx-mat-intl-tel-input {
  .ngx-mat-tel-input-container {
    button {
      opacity: 1 !important;
      font-family: Lato-Regular !important;
      pointer-events: none !important;
    }
    button:hover {
      background-color: white !important;
    }
    input:focus {
      --tw-ring-shadow: none;
    }
    input {
      padding: 0 6px 0 100px !important;
      color: #727272 !important;
      font-family: Lato-Regular !important;
    }
    .country-selector-code {
      color: #727272 !important;
    }
  }
}
.country-list-button {
  .label-wrapper {
    font-family: Lato-Regular !important;
    color: #727272 !important;
  }
}

.mat-tooltip.my-tooltip-separator {
  white-space: pre-line;
}

.marked {
  font-weight: 700 !important;
}

.mm {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

input:disabled {
  border-style: none !important;
}
textarea:disabled {
  border-style: none !important;
}

.link-class {
  a {
    color: #203868 !important;
    font-weight: 600 !important;
  }
}

.z-index-1000 {
  z-index: 1000 !important;
}
.z-index-1001 {
  z-index: 1001 !important;
}
.z-index-1002 {
  z-index: 1002 !important;
}

mat-chip.mat-standard-chip::after {
  background: transparent !important;
}

.mat-chip-list-wrapper {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.mat-standard-chip {
  height: auto !important;
}

div#myWidget iframe {
  position: relative !important;
  width: 100% !important;
}

div#myPaymentWidget iframe {
  position: relative !important;
  width: 100% !important;
}

#sug-box-store {
  z-index: 100 !important;
}

.zhigh {
  z-index: 100;
}
